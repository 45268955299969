import "./styles.css";

import CtaWpp from "../../components/CTAWpp";

import header1 from "../../assets/header1.png";
import header1Mobile from "../../assets/mobile/header1.png";
import frete from "../../assets/mobile/frete.png";
import logo_topo from "../../assets/logo_topo.png";
import header2_1 from "../../assets/header2_1.png";
import header2_2 from "../../assets/header2_2.png";
import header2_3 from "../../assets/header2_3.png";
import header3 from "../../assets/header3.png";

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container">
          <div className="left">
            {/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
              navigator.userAgent
            ) ? (
              <div className="mobile_top">
                <figure className="logo">
                  <img src={logo_topo} alt="Logo" />
                </figure>

                <figure className="frete">
                  <img src={frete} alt="Selo de Frete" />
                </figure>
              </div>
            ) : (
              <figure className="top">
                <img src={logo_topo} alt="Logo" />
              </figure>
            )}

            <h1 className="title">
              Peças originais <br />
              <span>para Ar-condicionado de todas as marcas</span>
            </h1>

            <figure className="mobile">
              <img src={header1Mobile} alt="Envio grátis" />
            </figure>

            <p className="text">
              Compre peças originais das melhores marcas para Ar-condicionado.
            </p>

            <CtaWpp />
          </div>

          <figure className="right">
            <img src={header1} alt="Envio grátis" />
          </figure>
        </article>
      </section>

      <section className="header2">
        <article className="container vertical">
          <h2 className="title">
            Peças para Ar-condicionado, a pronta entrega!
          </h2>

          <div className="gallery">
            <div className="item item1">
              <figure className="top">
                <img
                  src={header2_1}
                  alt="Filtro de óleo para todos os modelos de Ar-condicionado"
                />
              </figure>

              <div className="bottom">
                <p className="item_title">
                  Filtro de óleo para todos os modelos de Ar-condicionado
                </p>

                <p className="item_text">
                  Além de filtros de sucção, filtros secos e filtros de
                  separação de óleo e gás
                </p>

                <CtaWpp />
              </div>
            </div>

            <div className="item item1">
              <figure className="top">
                <img
                  src={header2_2}
                  alt="Componentes eletrônicos de todos os  modelos"
                />
              </figure>

              <div className="bottom">
                <p className="item_title">
                  Componentes eletrônicos de todos os modelos
                </p>
                <p className="item_text">
                  Oferecemos uma gama de peças eletrônicas originais para
                  reposição.
                </p>
                <CtaWpp />
              </div>
            </div>

            <div className="item">
              <figure className="top">
                <img src={header2_3} alt="Peças para manutenção e reposição" />
              </figure>

              <div className="bottom">
                <p className="item_title">Peças para manutenção e reposição</p>

                <p className="item_text">
                  Oferecemos uma variedade de peças para manutenção e reposição
                  de ar-condicionado.
                </p>

                <CtaWpp />
              </div>
            </div>
          </div>
        </article>
      </section>

      <section className="header3">
        <article className="container">
          <div className="left">
            <h2 className="title">
              Entregue um serviço de qualidade, com peças de qualidade!
            </h2>

            <figure className="mobile">
              <img src={header3} alt="Marcas que confiam" />
            </figure>

            <p className="text">
              Garanta a satisfação dos seus clientes, entregando um serviço de
              qualidade, com peças novas e originais.
            </p>

            <CtaWpp />
          </div>

          <figure className="right">
            <img src={header3} alt="Marcas que confiam" />
          </figure>
        </article>
      </section>
    </header>
  );
};

export default Header;
