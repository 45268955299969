import "./styles.css";

import CtaWpp from "../../components/CTAWpp";

import about1_1 from "../../assets/about1_1.png";
import about1_2 from "../../assets/about1_2.png";
import about1_3 from "../../assets/about1_3.png";
import about1_4 from "../../assets/about1_4.png";

const About = () => {
  return (
    <section id="about">
      <article className="about1">
        <div className="container vertical">
          <h2 className="title">Por que escolher a Cia dos Chillers?</h2>

          <p className="text">
            Escolha sempre o melhor para sua empresa e aproveite as vantagens
            que só nós podemos oferecer:
          </p>

          <div className="gallery">
            <div className="item">
              <figure className="top">
                <img src={about1_1} alt="Qualidade" />
              </figure>

              <p className="item_title">Qualidade</p>

              <p className="item_text">
                Oferecemos uma gama de peças novas e originais, das melhores
                marcas do mercado.
              </p>
            </div>

            <div className="item">
              <figure className="top">
                <img src={about1_2} alt="Preço justo" />
              </figure>

              <p className="item_title">Preço justo</p>

              <p className="item_text">
                Além dos valores competitivos, oferecemos também facilidade no
                pagamento.
              </p>
            </div>

            <div className="item">
              <figure className="top">
                <img src={about1_3} alt="A pronta entrega" />
              </figure>

              <p className="item_title">A pronta entrega</p>

              <p className="item_text">
                Tempos peças em estoque para venda a pronta entrega.
              </p>
            </div>

            <div className="item">
              <figure className="top">
                <img src={about1_4} alt="Envio grátis" />
              </figure>

              <p className="item_title">Envio grátis</p>

              <p className="item_text">
                Entregamos de forma gratuita para todo o território brasileiro.
              </p>
            </div>
          </div>
        </div>
      </article>

      <article className="about2">
        <div className="container vertical">
          <h2 className="title">Então não perca tempo!</h2>

          <p className="text">
            Fale conosco agora mesmo pelo WhatsApp e solicite um orçamento sem
            compromisso:
          </p>

          <CtaWpp text={"(11) 93392-2682"} />
        </div>
      </article>
    </section>
  );
};

export default About;
